import React from "react"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import UspBand from "../components/uspBand/uspBand"
import Reviews from "../components/reviews/reviews"
import ShapeGrid from "../components/shapeGrid/shapeGrid"
import Shape from "../components/shape/shape"
import goCardless from '../images/go-cardless.svg'



const DirectDebit = ({ data: { page, dd, shared }, location }) => {
  return (
    <>
      <SEO
        noIndex={true}
        titleOverride={"Thank You - Next step, Direct Debit"}
        descriptionOverride={"To make your life easier, fill out the secure form  to setup a direct debit, it makes reoccurring payments quicker and easier."}
        pathnameOverride={location.pathname}
      />

      <div className="container container--3xl">
        <div className="relative flex flex-wrap mb-16 md:mb-24 lg:mb-16">
          <div className="flex-1 mb-12 lg:mb-0">
            <ShapeGrid />
          </div>

          <div className="w-full mb-16 text-center lg:w-7/12 xl:w-5/12 lg:mb-0">
            <div className="flex flex-wrap items-center justify-center h-full">
              <div>
                <img src={goCardless} alt="GoCardless" loading="lazy" width="140px" className="mx-auto mb-4" />

                <span className="block px-5 text-5xl h1 lg:text-6xl xl:text-7xl 2xl:text-8xl lg:px-24 2xl:px-36 font-display leading-tiny mb-6">Thank You!</span>
               
                <div className="px-5 mb-6 font-sans text-xl font-light leading-tight sm:px-10 md:px-24">
                  <p className="mb-6">To make your life easier, and save you time each month, we've partnerd with <Link to="#">GoCardless</Link> to enable paying your reoccurring invoices with a direct debit.</p>
                  <p className="mb-6">If you haven't already, please click the button below and fill out the secure form to setup a direct debit.</p>
                  <p className="mb-6">Thank you again for choosing us,</p>
                  <p>Team Adtrak</p>
                </div>
                
                <div className="flex flex-wrap justify-center -mx-2">
                  <div className="px-2">
                    <Link to="https://adtrak.co.uk/directdebit/" className="btn btn--primary md:w-55">Setup Direct Debit</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1">
            <ShapeGrid />
          </div>
        </div>
      </div>

      <div className="container flex flex-wrap items-center justify-center py-4 mb-6 md:md:items-stretch lg:mb-24">
        <div className="flex flex-wrap justify-center md:w-full md:justify-between md:-mx-2">
            <div class="w-1/3 lg:w-1/5 p-1 md:p-4 flex">
                <div className="flex flex-wrap items-center justify-start w-full p-0 border-2 outline-none md:p-6 border-grey">
                    <div className="w-full m-auto md:px-2">
                        <div className="m-auto text-center gatsby-image-wrapper">
                            <Img fluid={shared.awardLogo.fluid} className="w-full" />
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="w-1/3 lg:w-1/5 p-1 md:p-4 flex">
                <div className="flex flex-wrap items-center justify-start w-full p-0 border-2 outline-none md:p-6 border-grey">
                <div className="w-full m-auto md:px-2">
                    <div className="m-auto text-center gatsby-image-wrapper">
                        <Img fluid={shared.webExcellenceAwardLogo.fluid} className="w-full" />
                    </div>
                </div>
                </div>
            </div>

        
            <div className="w-1/3 lg:w-1/5 p-1 md:p-4 flex">
                <div className="flex flex-wrap items-center justify-start w-full p-0 border-2 outline-none md:p-6 border-grey">
                    <div className="w-full m-auto md:px-2">
                        <div className="m-auto text-center gatsby-image-wrapper">
                            <Img fluid={shared.googlePartner.fluid} className="w-full" />
                        </div>
                    </div>
                </div>
            </div>
            
            
            <div class="w-full lg:w-2/5 p-4 flex">
                <div className="flex flex-wrap items-center justify-center w-full p-6 border-2 outline-none border-grey">
                    <div className="flex justify-center w-full md:flex-1 md:px-2">
                    <span className="block pb-2 mb-2 text-base text-center lg:pb-0 md:text-lg md:mb-0">Trusted by our many happy customers</span>
                    </div>
                    <div className="w-full md:w-auto md:px-2">
                        <div className="w-56 mx-auto text-center">
                            <Reviews hideExtraText />
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div> 
      
      {/* <section className="my-12 overflow-hidden lg:my-24">
        <div className="container">
          <div className="flex flex-wrap items-center md:-mx-1">
            {page.teamImages.map(({ fluid }, index) => {
              return(
                <div key={index} className="w-full mb-8 md:w-1/3 md:px-1">
                  <Img
                    className="w-full h-auto"
                    backgroundColor={`#3B5CC4`}
                    fluid={fluid}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </section> */}
    </>
  )
}

export default DirectDebit

export const query = graphql`
  
    query DirectDebit {
        dd: file(relativePath: { eq: "dd.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
                }
            }
        }
        shared: datoCmsShared {
            awardLogo {
              fluid(
                maxWidth: 400
                imgixParams: {h: "814", w: "300"}) {
                ...GatsbyDatoCmsFluid_noBase64
              }
            },
            agencyAwardLogo {
              fluid(
                maxWidth: 400
                imgixParams: {h: "814", w: "300"}) {
                ...GatsbyDatoCmsFluid_noBase64
              }
            },
            webExcellenceAwardLogo {
              fluid(
                maxWidth: 400
                imgixParams: {h: "514", w: "200"}) {
                ...GatsbyDatoCmsFluid_noBase64
              }
            }
            googlePartner {
              fluid(
                maxWidth: 400
                imgixParams: {h: "814", w: "300"}) {
                ...GatsbyDatoCmsFluid_noBase64
              }
            },
        }
        page: datoCmsWhoWeAre {
            title
            introduction
            introductionSupportingImage {
                fluid(imgixParams: {h: "692", w: "500"}) {
                ...GatsbyDatoCmsFluid_noBase64
                }
            }
            stats {
                heading
                text
            }
            content
            contentSupportingImage {
                fluid(imgixParams: {h: "800", w: "800"}) {
                ...GatsbyDatoCmsFluid_noBase64
                }
            }
            usps {
                heading
                blurb
            }
            teamImages {
                fluid(
                maxWidth: 900
                imgixParams: {h: "900", w: "900", fit: "crop", crop: "center"}) {
                ...GatsbyDatoCmsFluid_noBase64
                }
            }
            metaTags {
                title
                description
                twitterCard
                image {
                url
                }
            }
            slug
        }
    }
`